<template>
  <div>
    <v-app-bar
      class="header-toolbar"
      :class="{ 'pl-2': !$vuetify.breakpoint.mobile }"
      color="accent-4"
      flat
      dense
      :fixed="$vuetify.breakpoint.mobile ? false : true"
      extension-height="5px"
    >
      <router-link :to="{ name: 'dashboard' }" class="logo">
        <v-img
          class="logo-image"
          :src="$vuetify.theme.dark ? '/img/logo-ranes-darkmode.png' : '/img/logo-ranes-lightmode.png'"
          max-width="113"
          contain
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="showMenu" :close-on-content-click="false" v-if="user">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <AppUserAvatar :user="user"></AppUserAvatar>
          </div>
        </template>
        <v-card>
          <v-list max-width="300">
            <v-list-item class="mt-3 pl-2">
              <v-list-item-avatar>
                <AppUserAvatar :user="user"></AppUserAvatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-nunito">{{ user.friendlyName }}</v-list-item-title>
                <v-list-item-subtitle class="font-nunito">{{ user.userEmailAddress }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-nunito">{{ user.companyName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item link @click="showMenu = false" :to="{ name: 'account' }">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("c_header.t_account_settings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="showMenu = false" :to="{ name: 'help' }">
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("c_header.t_help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar flat height="68" class="transparent">
              <TheLanguageSwitcher />
            </v-toolbar>
          </v-list>

          <v-divider></v-divider>
          <v-btn
            @click="
              unregisterFCMToken();
              logout();
            "
            text
            block
            plain
            large
            class="rounded-0 pl-4"
            style="margin-left: 2px"
          >
            <v-icon>mdi-logout</v-icon>
            <span class="ml-7">{{ $t("c_header.t_log_out") }}</span
            ><v-spacer />
          </v-btn>
          <!-- <v-btn
            @click="
              unregisterFCMToken();
              logout();
            "
            block
            tile
            class="text-align-left"
            >Log out</v-btn
          > -->
        </v-card>
      </v-menu>

      <template v-slot:extension>
        <div class="progress-wrap">
          <v-progress-linear
            v-if="loading"
            indeterminate
            background-opacity=".4"
            color="red"
            height="4"
          ></v-progress-linear>
          <v-progress-linear v-else background-opacity="1" color="red" height="4"></v-progress-linear>
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppUserAvatar from "@/components/AppUserAvatar.vue";
import TheLanguageSwitcher from "@/components/TheLanguageSwitcher";

export default {
  name: "TheHeader",
  components: {
    AppUserAvatar,
    TheLanguageSwitcher,
  },
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    ...mapState("location", ["geoLocationCoords"]),
    ...mapState("loader", ["loading"]),
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    ...mapActions("settings", ["upsertSetting", "toggleTimeFormat", "toggleDarkMode"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("ui", ["navDrawer"]),
    ...mapActions("notifications", ["unregisterFCMToken"]),

    openDrawer() {
      this.navDrawer("open");
    },
  },
};
</script>
<style lang="scss">
.progress-wrap {
  width: calc(100% + 60px);
  z-index: 9999;
  margin: 0 -30px 0 -30px;
}
.header {
  .v-toolbar__content {
    background: #f5f5f5;
    padding: 4px 10px;
  }
  .logo-image {
    margin-top: 2px;
  }
}
.theme--dark {
  .header .v-toolbar__content {
    background: #272727;
  }
}
</style>
